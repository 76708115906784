<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->

                    <Conclusion v-if="key == 'detail_Conclusion' && item.show" />
                    
                    <!--1indication start-->         
                    <Indication v-else-if="key=='detail_indication' && item.show"/>
                    <!--indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->
                    
                    <!-- key_document -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!--Guidance Recommandation start-->                
                    <div class="content-onglets"  v-else-if="key == 'detail_guidance_recommandation' && item.show">                                
                        <p v-html="check_empty(data.singapore['guidance_recommandation'])" ></p>
                    </div>
                    <!--Guidance Recommandation end-->

                    <!--Technology Evaluation start-->       
                    <div class="content-onglets"  v-else-if="key == 'detail_technology_evaluation' && item.show">                            
                        <p v-html="check_empty(data.singapore['technology_evaluation'])" ></p>
                    </div>                
                    <!--Technology Evaluation end-->

                    <!--Clinical Aspects start-->  
                    <div class="content-onglets"   v-else-if="key == 'detail_clinical_aspects' && item.show">                    
                        <div class="text-left box-toggle " v-if="data.singapore['clinical_need']">
                            <a class="title title-tb"  style="cursor: pointer;" @click="clinical_need_Show = !clinical_need_Show">  
                                Clinical Need  <em :class="clinical_need_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>  
                            </a> 
                            <div style="text-align: justify;" v-if="clinical_need_Show">
                                <p v-html="data.singapore['clinical_need']"></p>
                            </div> 
                        </div>
                        <div class="text-left box-toggle " v-if="data.singapore['clinical_effectiveness_safety']"> 
                            <a class="title title-tb" style="cursor: pointer;" @click="clinical_effectiveness_safety_Show = !clinical_effectiveness_safety_Show">  
                                Clinical Effectiveness and Safety <em :class="clinical_effectiveness_safety_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>  
                            </a>   
                            <div style="text-align: justify;" v-if="clinical_effectiveness_safety_Show">
                                <p v-html="data.singapore['clinical_effectiveness_safety']"></p>
                            </div>                          
                           
                        </div>                    
                    </div>                   
                    <!--Clinical Aspects end-->

                    <!--Economic Evaluation start-->      
                    <div class="content-onglets" v-else-if="key == 'detail_economic_evaluation' && item.show">
                        <div class="text-left box-toggle" v-if="data.singapore['cost_effectiveness']">   
                            <a class="title title-tb"  style="cursor: pointer;" @click="cost_effectiveness_Show = !cost_effectiveness_Show">
                                Cost Effectiveness  <em :class="cost_effectiveness_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em> 
                            </a>                       
                            <div style="text-align: justify;" v-if="cost_effectiveness_Show">
                                <p v-html="data.singapore['cost_effectiveness']"></p>
                            </div>                             
                        </div> 
                        <div class="text-left box-toggle" v-if="data.singapore['estimated_annual']"> 
                            <a class="title title-tb"   style="cursor: pointer;" @click="estimated_annual_Show = !estimated_annual_Show">
                                Estimated Annual Technology Cost  <em :class="estimated_annual_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em> 
                            </a>                        
                            <div style="text-align: justify;" v-if="estimated_annual_Show">
                                <p v-html="data.singapore['estimated_annual']"></p>
                            </div>
                        </div>                    
                    </div>          
                    <!--Economic Evaluation end-->

                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.singapore.evaluation_economic" />

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                        
                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.singapore['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->    

                    <!-- Additional Considerations start-->      
                    <div class="content-onglets" v-else-if="key == 'detail_additional_considerations' && item.show">
                        <p v-html="check_empty(data.singapore['additional_considerations']) "></p>
                    </div> 
                    <!--Additional Considerations end-->

                    <!--Recommandation start-->            
                    <div class="content-onglets" v-else-if="key == 'detail_recommandation' && item.show">
                        <p v-html="check_empty(data.singapore['recommandation']) "></p>                        
                    </div>            
                    <!--Recommandation end-->

                    <!--@todo detail_EssaisClinique -->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                    <!-- Section Detail End -->

                </div>
            </template>

            <!-- Link Section Start-->       
           <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
			

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import BudgetImpact from '../budget_impact.vue'
import KeyDocument from '../key_document.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Indication from '../Indication.vue'
import { check_empty, check_detail_conclusion, key_documents } from '../../../utils'
export default {
    name: 'singapore',
    components : {
        LeftSection,
        TreatmentLine,
        EvaluationEconomic, 
        EconomicEvaluation,     
        Conclusion,
        LinkAgency,         
        BudgetImpact,
        KeyDocument,
        EssaisClinique,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_Conclusion : {'title' : 'Rationale and commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_indication  : {'title' : 'Indication under review', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_guidance_recommandation : {'title' : 'Guidance Recommandation', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_technology_evaluation : {'title' : 'Technology Evaluation', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_clinical_aspects : {'title' : 'Clinical Aspects', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_economic_evaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_BudgetImpact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_additional_considerations : {'title' : 'Additional Considerations', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_recommandation : {'title' : 'Recommandation', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_clinical_trials : {'title':'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},
            },
            clinical_effectiveness_safety_Show : false,
            clinical_need_Show : false,
            cost_effectiveness_Show : false,
            estimated_annual_Show : false,

        }
    },
    created(){
        // condition to show check box in left section [enable]
        if (this.data && this.data.singapore) { 

            if(this.check_detail_conclusion(this.data, this.$i18n.locale) ||
                this.data.singapore['limitation'] ||
                this.$i18n.locale != 'es' && this.data.singapore['rationale_and_commentary_original'] ||
                this.$i18n.locale == 'es' && this.data.singapore['rationale_and_commentary'] ) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if (this.data['indication_en']){
                this.list_check_box['detail_indication'].enable = true                        
            }    
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if (this.data.singapore['guidance_recommandation']){
                this.list_check_box['detail_guidance_recommandation'].enable =true
            }
            if (this.data.singapore['technology_evaluation']){
                this.list_check_box['detail_technology_evaluation'].enable = true
            }
            if (this.data.singapore['clinical_need'] || this.data.singapore['clinical_effectiveness_safety']) {
                this.list_check_box['detail_clinical_aspects'].enable = true
            }
            if (this.data.singapore['cost_effectiveness'] || this.data.singapore['estimated_annual']){
                this.list_check_box['detail_economic_evaluation'].enable = true
            }                    
            if(this.data.singapore.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }                
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false                    
            }        
            if(this.data.singapore['budget_impacts'] && this.data.singapore['budget_impacts'].length > 0 ) { 
                this.list_check_box['detail_BudgetImpact'].enable = true
            }                
            if (this.data.singapore['additional_considerations']){
                this.list_check_box['detail_additional_considerations'].enable = true
            }
            if (this.data.singapore['recommandation']){
                this.list_check_box['detail_recommandation'].enable = true
            }
                
            // this.list_check_box['detail_EssaisClinique'].enable = true
            if(this.data.essais_clinique &&  this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }  

        }   
    
    },
    methods : {
        check_empty ,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },        			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }     
    },
}
</script>

<style>

</style>